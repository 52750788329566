export type CompareFnc<T> = (a: T, b: T) => boolean
export const defaultCompare: CompareFnc<any> = (a, b) => a === b

export function mergeData<T>(sources: Array<T>[], compare: CompareFnc<T> = defaultCompare): Array<T> {
  const final = [...sources[0]]
  for(let i = 1; i < sources.length; i++) {
    for(let j = 0; j < sources[i].length; j++) {
      if (!final.find(k => compare(k, sources[i][j]))) {
        final.push(sources[i][j])
      }
    }
  }
  return final
}

export function mergeById<T extends { _id: string }>(sources: Array<T>[]): Array<T> {
  return mergeData(sources, (a: T, b: T) => a._id === b._id)
}
