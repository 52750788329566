import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useApiContext } from '../../api/apiContext'
import { Api } from '../../api'
import { Header } from '../../components/Header'
import { Event } from '../../components/Event'
import { mergeById } from '../../utils/data'

export const EventRoute: React.FC = ({}) => {
  const reloadInterval = useRef<number>()
  const apiContext = useApiContext()
  const [loading, setLoading] = useState(true)

  const reachedEnd = useRef(false)

  const getData = async (skip: number) => {
    const result = await Api.getEvent(apiContext.token, skip)
    const items = mergeById([apiContext?.event?.items || [], result.items])
    items.sort((a, b) => b.timestamp - a.timestamp)
    apiContext.setData({ event: { items, count: result.count } })
    setLoading(false)
  }

  useEffect(() => {
    apiContext.call([(token) => token && Api.getDevices(token)])
    getData(0)
  }, [])

  const handleScroll = useCallback((e) => {
    const max = (document.scrollingElement as any).offsetHeight - (window as any).innerHeight
    const top = (document.scrollingElement as any).scrollTop
    if (max - top < 50) {
      if (!reachedEnd.current) {
        reachedEnd.current = true
        if (!loading) {
          getData(apiContext?.event?.items?.length || 0)
          setLoading(true)
        }
      }
    } else {
      if (reachedEnd.current) {
        reachedEnd.current = false
      }
    }
  }, [loading, reachedEnd])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    window.clearInterval(reloadInterval.current)
    reloadInterval.current = window.setInterval(() => getData(0), 5000) // get latest in interval
    return () => {
      window.clearInterval(reloadInterval.current)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [apiContext])

  const handleEventChange = useCallback((id: string, type: string) => {
    apiContext.call([(token) => token && Api.patchEvent(token, id, { type })])
  }, [])

  const handleConfirmChange = useCallback((id: string, confirmed: boolean) => {
    apiContext.call([(token) => token && Api.patchEvent(token, id, { confirmed })])
  }, [])

  return (
    <>
      <Header />
      <h1>Event view</h1>
      {(apiContext?.event?.items || []).map(i => (
        <Event data={i} key={`data-${i.timestamp}`} onEventChange={handleEventChange} onEventConfirmed={handleConfirmChange}/>
      ))}
    </>
  )
}
