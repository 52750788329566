import styled from '@emotion/styled'
import { mediaQSmall } from '../../utils/responsivity'

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #af00c9;
  opacity: 0.9;
  background-image:  repeating-radial-gradient( circle at 0 0, #9715ab 0, #982fa8 10px ), repeating-linear-gradient( #89029e, #9202a8 );
`

export const Container = styled.div`
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  padding: 100px 80px 0 80px;
  width: 300px;
  background: #fff;
  min-height: calc(100vh - 100px);
  margin-left: 150px;
  @media ${mediaQSmall} {
    width: calc(100% - 160px);
    padding: 65px 40px 80px 40px;
    margin: 0 auto;
    min-height: auto;
  }
`

export const LogoImage = styled.img`
  margin-bottom: 50px;
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Error = styled.div`
  margin: 10px 0;
  color: red;
`
