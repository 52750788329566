import { css } from '@emotion/react'

export const GLOBAL_STYLE = css`
  body {
    background: white;
    margin: 0;
    padding: 0;
    min-height: '100vh';
    max-width: '100vw';
    font-family: Arial;
    font-size: 12px;
  }

  * {
    font-family: Arial;
    font-size: 12px;
  }
`
