import React from 'react'
import { Header } from '../../components/Header'

export const HomeRoute: React.FC = ({}) => {
  return (
    <>
      <Header />
      <h1>iqSaucer console</h1>
    </>
  )
}
