import { HTTP_STATUS } from '../utils/http'
import { normalizeUrl } from '../utils/url';
import { Request } from '../utils/Request'
import { ApiSession, ApiEventEntity, ApiReportEntity, ApiDevice, ApiGetEntity } from './interfaces';

// tslint:disable-next-line: no-var-requires
const config = require('config');

// set by location host
let serverUrl = config.serverUrl;
if (serverUrl.indexOf('http://') !== 0 && serverUrl.indexOf('https://') !== 0) {
    serverUrl = `${location.protocol}//${window.location.host}${config.serverUrl}`;
}

export const USER_SESSION_HEADER = 'user-session-token'

export class Api {
  /**
   * Login
   * Creates session and returns session and expiration date
   */
  public static async login(
    email: string,
    password: string,
  ) {
    return (await Request.post<{session: ApiSession}>(normalizeUrl(serverUrl + '/session'), {}, {
      email,
      password,
    }))
    .expectStatus(HTTP_STATUS.CREATED).data
  }

  /**
   * Prolong session
   */
  public static async getSession(
    token: string,
  ) {
    return (await Request.get<{session: ApiSession}>(normalizeUrl(serverUrl + '/session'), {
      [USER_SESSION_HEADER]: token,
    }))
    .expectStatus(HTTP_STATUS.OK).data
  }

  /**
   * Get event
   */
  public static async getEvent(
    token: string,
    skip: number = 0,
    limit: number = 10,
  ) {
    return (await Request.get<{items: ApiGetEntity<ApiEventEntity>[]; count: number; }>(normalizeUrl(serverUrl + `/event?skip=${skip}&limit=${limit}`), {
      [USER_SESSION_HEADER]: token,
    }))
    .expectStatus(HTTP_STATUS.OK).data;
  }

  /**
   * Patch event
   */
  public static async patchEvent(
    token: string,
    entityId: string,
    event: {
      type?: string,
      confirmed?: boolean,
    }
  ) {
    return (await Request.patch<{}>(normalizeUrl(serverUrl + `/event/${entityId}`), {
      [USER_SESSION_HEADER]: token,
    }, {
      event,
    }))
    .expectStatus(HTTP_STATUS.OK).data;
  }

  /**
   * Get event
   */
  public static async getReport(
    token: string,
    skip: number = 0,
    limit: number = 10,
  ) {
    return (await Request.get<{items: ApiGetEntity<ApiReportEntity>[]; count: number; }>(normalizeUrl(serverUrl + `/report?skip=${skip}&limit=${limit}`), {
      [USER_SESSION_HEADER]: token,
    }))
    .expectStatus(HTTP_STATUS.OK).data;
  }

  /**
   * Patch event
   */
  public static async patchReport(
    token: string,
    entityId: string,
    report: {
      type?: string,
      confirmed?: boolean,
    }
  ) {
    return (await Request.patch<{}>(normalizeUrl(serverUrl + `/report/${entityId}`), {
      [USER_SESSION_HEADER]: token,
    }, {
      report,
    }))
    .expectStatus(HTTP_STATUS.OK).data;
  }

  /**
   * Get devices
   */
  public static async getDevices(
    token: string,
  ) {
    return (await Request.get<{devices: ApiDevice[] }>(normalizeUrl(serverUrl + `/user/device`), {
      [USER_SESSION_HEADER]: token,
    }))
    .expectStatus(HTTP_STATUS.OK).data;
}
}
