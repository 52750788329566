import { isString } from './typechecks'
import { replaceAll } from './string'
import { HTTP_STATUS } from './http'

declare function fetch(url: string, params: any)

interface RequestParams {
  method: string
  credentials: string
  headers: { [name: string]: string }
  body?: any
}

export class RequestError extends Error {
  constructor(
    message: string,
    public readonly url: string,
    public readonly status: number,
    public readonly originalError?: Error,
    public readonly data?: any,
  ) {
    super(message)
    const actualProto = new.target.prototype
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto)
    } else {
      ;(this as any).__proto__ = actualProto
    }
  }
}

/**
 * Response object
 */
export class ResponseObject<T> {
  constructor(
    public readonly url: string,
    public readonly status: number,
    public readonly data: T,
    public readonly headers: { [name: string]: string },
  ) {}

  /**
   * Check status
   */
  public expectStatus(status: number): ResponseObject<T> {
    if (this.status !== status) {
      throw new RequestError(`Expected response status was ${status}, but it was ${this.status}.`, this.url, this.status, null, this.data)
    }
    return this
  }

  /**
   * Check status
   */
  public unauthorized(callback: () => void): ResponseObject<T> {
    if (this.status === HTTP_STATUS.UNAUTHORIZED) {
      callback()
      throw new RequestError(`Response status was ${this.status} - UNAUTHORIZE.`, this.url, this.status, null, this.data)
    }
    return this
  }
}

export class Request {
  /**
   * Harse header string
   * @param header
   */
  public static parseHeader(header: string): { [key: string]: any } {
    if (!isString(header)) {
      return {}
    }

    const p = header.split(';').map(i => i.trim())
    const items = p.map(parts => parts.split('='))
    return items.reduce((result, item) => {
      const name = item[0]
      result[name] = isString(item[1]) ? replaceAll(item[1], '"', '') : item[1]
      return result
    }, {})
  }

  /*
   *
   *  Base api requests
   *
   */
  public static async base<T>(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS',
    headers: any = null,
    data: any = null,
    forceContentType: boolean = true,
    isJson: boolean = true,
  ): Promise<ResponseObject<T>> {
    const params = {
      method,
      credentials: 'same-origin',
      mode: 'cors',
      headers: {
        ...headers,
      },
      body: undefined,
    }

    if (method !== 'GET' || forceContentType) {
      params.headers['Content-Type'] = 'application/json'
    }

    if (data) {
      params.body = JSON.stringify(data)
    }

    let response = null
    let responseData = null
    try {
      response = await fetch(url, params)
      if (isJson) {
        responseData = await response.json()
      } else {
        responseData = response
      }
    } catch (e) {
      throw new RequestError(`Request failed`, `${method} ${url}`, response ? response.status : null, e)
    }

    return new ResponseObject(`${method} ${url}`, response.status, responseData, response.headers)
  }

  public static get<T>(url: string, headers: { [key: string]: string } = null, forceContentType: boolean = true, isJson: boolean = true) {
    return Request.base<T>(url, 'GET', headers, null, forceContentType, isJson)
  }

  public static post<T>(url: string, headers: { [key: string]: string } = null, data = {}, forceContentType: boolean = true, isJson: boolean = true) {
    return Request.base<T>(url, 'POST', headers, data, forceContentType, isJson)
  }

  public static put<T>(url: string, headers: { [key: string]: string } = null, data = {}, forceContentType: boolean = true, isJson: boolean = true) {
    return Request.base<T>(url, 'PUT', headers, data, forceContentType, isJson)
  }

  public static patch<T>(
    url: string,
    headers: { [key: string]: string } = null,
    data = {},
    forceContentType: boolean = true,
    isJson: boolean = true,
  ) {
    return Request.base<T>(url, 'PATCH', headers, data, forceContentType, isJson)
  }

  public static delete<T>(
    url: string,
    headers: { [key: string]: string } = null,
    data = {},
    forceContentType: boolean = true,
    isJson: boolean = true,
  ) {
    return Request.base<T>(url, 'DELETE', headers, data, forceContentType, isJson)
  }
}
