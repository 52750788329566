import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Input = styled.input<{}>(
  ({ theme }) => css`
  display: block;
  margin-bottom: 20px;
  padding: 5px;
  appearance: none;
  padding: 12px 15px;
  outline: none;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid ${theme.color.primary};
  border-radius: 15px;
  width: 100%;
  `,
)
