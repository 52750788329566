import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ApiContextData } from './interfaces'

export interface ApiContextProps extends ApiContextData {
  token: string
  setData: (value: Partial<ApiContextData>) => Partial<ApiContextData>
  call: (actions: ((token: string) => Promise<Partial<ApiContextData>>)[]) => Promise<Partial<ApiContextData>>
  onError: (e: Error) => void
}

export const ApiContext = React.createContext<ApiContextProps>(undefined)
export const ApiContextProvider: React.FC<{token?: string; onError?: (e) => void} & React.PropsWithChildren> = ({ children, token, onError }) => {

  // read context
  const context = useContext(ApiContext) as ApiContextProps
  const [internalData, setInternalData] = useState({})
  const currentData = useRef({})

  // real value to be used here
  const value = useMemo(() => ({
    token,
    onError,
    ...context,
    ...currentData.current,
  }), [internalData, context, token])

  // set data function
  const setData = (value: Partial<ApiContextData>) => {
    currentData.current = {
      ...currentData.current,
      ...value,
    }
    setInternalData(currentData.current)
    return currentData.current
  }

  // call action function
  const call = async (actions: ((token: string) => Promise<Partial<ApiContextData>>)[]) => {
    try {
      return setData(
        (await Promise.all(actions.map(a => a(value.token))))
        .reduce((acc, result) => ({...acc, ...result}), {})
      )
    } catch(e) {
      if (value.onError) {
        value.onError(e)
      }
    }
  }

  // final context
  const ctxAssigned = useMemo(() => ({
    ...value,
    setData,
    call,
  }), [value])

  return (
    <ApiContext.Provider value={ctxAssigned}>
      {children}
    </ApiContext.Provider>
  )
}

export const useApiContext = (): ApiContextProps => {
  const context = useContext(ApiContext) as ApiContextProps
  if (!context) {
    throw new Error('This component must be used with api context provider')
  }
  return context
}