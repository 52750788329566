import React, { useCallback, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Api } from '../../api'
import { useDispatch } from 'react-redux'
import { login } from '../../state/app'
import { useApiContext } from '../../api/apiContext'
import * as Styled from './index.styles'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'

import logo from '../../../assets/logo.svg'
import { RequestError } from '../../utils/Request'
import { HTTP_STATUS } from 'ts/utils/http'

type FormValues = {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () =>{
  const { register, handleSubmit } = useForm<FormValues>()
  const dispatch = useDispatch()
  const apiContext = useApiContext()
  const [error, setError] = useState()

  const onSubmit: SubmitHandler<FormValues> = useCallback(async (data) => {
    if (data.email && data.password) {
      try {
        const session = apiContext.setData(await Api.login(data.email, data.password)).session
        dispatch(login({ token: session.token, expires: session.expiration }))
      } catch(e) {
        if (e instanceof RequestError) {
          setError(e.data.message || e.message)
        } else {
          setError(e.message)
        }
      }
    }
  }, [])

  return (
    <Styled.Background>
      <Styled.Container>
        <Styled.LogoImage src={logo} />
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Input type="email" {...register("email")} placeholder="Email" />
          <Input type="password" {...register("password")} placeholder="Password" />
          <Button value="Login" />
          {error && <Styled.Error>{error}</Styled.Error>}
        </Styled.Form>
      </Styled.Container>
    </Styled.Background>
  )
}
