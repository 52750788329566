import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as cookies from 'browser-cookies';

const SESSION_COOKIE_NAME = 'SESSION'

export const initialState: {
  token: string
} = {
  token: cookies.get(SESSION_COOKIE_NAME),
}

export type AppState = typeof initialState

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    login(state: AppState, action: PayloadAction<{ token: string, expires?: number }>) {
      cookies.set(SESSION_COOKIE_NAME, action.payload.token, action.payload.expires ? { expires: action.payload.expires } : undefined)
      return {
        ...state,
        token: action.payload.token,
      }
    },
    logout(state: AppState) {
      cookies.erase(SESSION_COOKIE_NAME)
      return {
        ...state,
        token: undefined,
      }
    },
  },
})

export const { login, logout } = appSlice.actions
export default appSlice.reducer
