import { css } from '@emotion/react'
import styled from '@emotion/styled'


export const Container = styled.div(
  ({ theme }) => css`
    display: sticky;
    left: 0;
    top: 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
    padding: 10px 15px;
    background: ${theme.color.primary};
    height: 50px;
    display: flex;
  `,
)

export const LogoImage = styled.img`
  height: 50px;
  position: relative;
  top: 5px;
`
