import React, { useCallback } from 'react'
import { Global, css } from '@emotion/react'
import { GLOBAL_STYLE } from '../constants/styles'
import { Routes, Route } from 'react-router-dom'
import { HomeRoute } from '../routes/Home'
import { EventRoute } from '../routes/Event'
import { ReportRoute } from '../routes/Report'
import { LoginRequired } from '../components/LoginRequired'
import { ApiContextProvider } from '../api/apiContext'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../state/store'
import { RequestError } from '../utils/Request'
import { HTTP_STATUS } from '../utils/http'
import { logout } from '../state/app'

export const App: React.FC = ({}) => {
  const dispatch = useDispatch()
  const token = useSelector((state: IStore) => state?.app?.token)
  const handleError = useCallback((e) => {
    if (e instanceof RequestError && e.status === HTTP_STATUS.UNAUTHORIZED) {
      dispatch(logout())
    }
  }, [dispatch])

  return (
    <>
      <Global styles={GLOBAL_STYLE} />
      <ApiContextProvider token={token} onError={handleError}>
        <LoginRequired>
          <Routes>
            <Route path="/" element={<HomeRoute />} />
            <Route path="/event" element={<EventRoute />} />
            <Route path="/report" element={<ReportRoute />} />
          </Routes>
        </LoginRequired>
      </ApiContextProvider>
    </>
  )
}
