import React, { useCallback, useMemo, useState } from 'react'
import { ApiEventEntity, ApiGetEntity } from '../../api/interfaces'
import * as Styled from './index.styles'
import { LineChart, XAxis, CartesianGrid, Line, Tooltip } from 'recharts'
import { interpolateArray } from '../../utils/interpolate'

export const ANALYZIS_EVENTS = ['THREE_KNOCKS', 'REFILL_IN_PLACE', 'DRINK_IN_PLACE', 'UNKNOWN']
export interface Props {
  data: ApiGetEntity<ApiEventEntity>
  onEventChange: (id: string, event: string) => void
  onEventConfirmed: (id: string, confirmed: boolean) => void
}

export const Event: React.FC<Props> = ({ data, onEventChange, onEventConfirmed }) => {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [confirmedEvent, setConfirmedEvent] = useState(null)

  const handleChangeEvent = useCallback((e) => {
    setSelectedEvent(e.target.value)
    onEventChange(data._id, e.target.value)
  }, [])

  const handleChangeConfirmed = useCallback((e) => {
    setConfirmedEvent(e.target.checked)
    onEventConfirmed(data._id, e.target.checked)
  }, [])

  const dateTime = useMemo(() => data.timestamp ? new Date(data.timestamp).toLocaleString() : 'N/A', [data])
  const graphData = useMemo(() => {
    const input = (data?.event || [])
    const output = []

    for(let index = 0; index < input.length; index++) {
      const weight = input[index]

      output.push({
        weight,
        index: input.length - index,
      })
    }

    let count = 0
    for(let index = 0; index < Math.min(4, input.length); index++) {
      count += input[index]
    }
    const stable = count / Math.min(4, input.length)

    return {
      output,
      stable,
    }
  }, [data])

  const trainData = useMemo(() => {
    const inputData = interpolateArray(graphData.output.map(i => i.weight), 20)
    return inputData.map((weight, index) => {
      return {
        index: inputData.length - index,
        weight: Math.round(weight / 50),
      }
    })
  }, [graphData])

  return (
    <Styled.Container>
      <Styled.Chart>
        <LineChart
          width={Math.min(window.innerWidth - 40, 400)}
          height={100}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <Tooltip />
          <XAxis type="number" dataKey="index" />
          <CartesianGrid stroke="#f5f5f5" />
          <Line data={graphData.output} type="monotone" dataKey="weight" stroke="#89029e" xAxisId={0} yAxisId={0} />
        </LineChart>
      </Styled.Chart>

      <Styled.Data>
        <Styled.Table>
          <Styled.TableRow>
            <Styled.TableCellkey>Time:</Styled.TableCellkey><Styled.TableCell>{dateTime}</Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>Stable weight:</Styled.TableCellkey><Styled.TableCell>{graphData.stable}g</Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>Event length:</Styled.TableCellkey><Styled.TableCell>{graphData.output.length}</Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>
              Event type:
            </Styled.TableCellkey>
            <Styled.TableCell>
              <select value={selectedEvent || data?.type || 'UNKNOWN'} onChange={handleChangeEvent}>
                {ANALYZIS_EVENTS.map(e => <option key={e} value={e}>{e}</option>)}
              </select>
            </Styled.TableCell>
          </Styled.TableRow>

          <Styled.TableRow>
            <Styled.TableCellkey>
            </Styled.TableCellkey>
            <Styled.TableCell>
              <label>
                <input type="checkbox" checked={confirmedEvent === null ? data?.confirmed : confirmedEvent} onChange={handleChangeConfirmed}/>
                Event confirmed
              </label>
            </Styled.TableCell>
          </Styled.TableRow>

        </Styled.Table>
      </Styled.Data>

    </Styled.Container>
  )
}
