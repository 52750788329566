import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mediaQSmall } from '../../utils/responsivity'


export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 20px;
    background: #f0f0f0;
    margin-bottom: 15px;
    @media ${mediaQSmall} {
      flex-direction: column;
    }
  `,
)

export const Chart = styled.div(
  ({ theme }) => css`
    min-height: 150px;
  `,
)

export const Data = styled.div(
  ({ theme }) => css`
    flex: 1;
  `,
)

export const Table = styled.div(
  ({ theme }) => css`
    display: table;
    font-size: 14px;
  `,
)

export const TableRow = styled.div(
  ({ theme }) => css`
    display: table-row;
  `,
)

export const TableCell = styled.div(
  ({ theme }) => css`
    display: table-cell;
    font-size: 14px;
    padding: 5px;
  `,
)

export const TableCellkey = styled(TableCell)`
  font-weight: bold;
`
