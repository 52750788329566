import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Input = styled.input<{outline?: boolean}>(
  ({ theme, outline }) => css`
    cursor: pointer;
    display: block;
    margin-bottom: 15px;
    padding: 10px;
    line-height: 20px;
    height: 40px;
    min-width: 100px;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    ${outline ? `
    background: none;
    border: 1px solid ${theme.color.primary};
    color: ${theme.color.primary};
    ` : `
    background: ${theme.color.primary};
    border: none;
    color: #ffffff;
    `}
  `,
)
