import React, { useCallback, useMemo, useState } from 'react'
import { ApiGetEntity, ApiReportEntity } from '../../api/interfaces'
import * as Styled from './index.styles'

export const ANALYZIS_EVENTS = ['NEW_SEQUENCY', 'CONTINUE', 'UNKNOWN']
export interface Props {
  data: ApiGetEntity<ApiReportEntity>
  previousData: ApiGetEntity<ApiReportEntity>
  onEventChange: (id: string, event: string) => void
  onEventConfirmed: (id: string, confirmed: boolean) => void
}

export const Report: React.FC<Props> = ({ data, previousData, onEventChange, onEventConfirmed }) => {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [confirmedEvent, setConfirmedEvent] = useState(null)

  const handleChangeEvent = useCallback((e) => {
    setSelectedEvent(e.target.value)
    onEventChange(data._id, e.target.value)
  }, [])

  const handleChangeConfirmed = useCallback((e) => {
    setConfirmedEvent(e.target.checked)
    onEventConfirmed(data._id, e.target.checked)
  }, [])

  const dateTime = useMemo(() => data.timestamp ? new Date(data.timestamp).toLocaleString() : 'N/A', [data])

  return (
    <Styled.Container>

      <Styled.Data>
        <Styled.Table>
          <Styled.TableRow>
            <Styled.TableCellkey>Time:</Styled.TableCellkey><Styled.TableCell>{dateTime}</Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>Weight:</Styled.TableCellkey><Styled.TableCell>{typeof previousData?.snapshot?.weight === 'number' ? previousData.snapshot.weight : 'N/A'}  {` -> `}  {data.snapshot.weight}</Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>Temperature:</Styled.TableCellkey><Styled.TableCell>
              {typeof previousData?.snapshot?.temperature === 'number' ? previousData.snapshot.temperature : 'N/A'}  {` -> `}  {typeof data?.snapshot?.temperature === 'number' ? data.snapshot.temperature : 'N/A'}
            </Styled.TableCell>
            <Styled.TableCellkey>Ambient temperature:</Styled.TableCellkey><Styled.TableCell>
              {typeof previousData?.snapshot?.ambient?.temperature === 'number' ? previousData.snapshot.ambient.temperature : 'N/A'}  {` -> `}  {typeof data?.snapshot?.ambient?.temperature === 'number' ? data.snapshot.ambient.temperature : 'N/A'}
            </Styled.TableCell>
          </Styled.TableRow>
          <Styled.TableRow>
            <Styled.TableCellkey>
              Event type:
            </Styled.TableCellkey>
            <Styled.TableCell>
              <select value={selectedEvent || data?.type || 'UNKNOWN'} onChange={handleChangeEvent}>
                {ANALYZIS_EVENTS.map(e => <option key={e} value={e}>{e}</option>)}
              </select>
            </Styled.TableCell>
          </Styled.TableRow>

          <Styled.TableRow>
            <Styled.TableCellkey>
            </Styled.TableCellkey>
            <Styled.TableCell>
              <label>
                <input type="checkbox" checked={confirmedEvent === null ? data?.confirmed : confirmedEvent} onChange={handleChangeConfirmed}/>
                Event confirmed
              </label>
            </Styled.TableCell>
          </Styled.TableRow>

        </Styled.Table>
      </Styled.Data>

    </Styled.Container>
  )
}
