export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALOWED: 405,
  UNSUPORTED_MEDIA_TYPE: 415,
  SERVER_ERROR: 500,
}
