import React from 'react'
import * as Styled from './index.styles'

export interface Props {
  value: string
  type?: string
  outline?: boolean
}

export const Button: React.FC<Props> = ({ value, type = 'submit', outline = false }) => {
  return <Styled.Input type={type} value={value} outline={outline} />
}
