import { configureStore } from '@reduxjs/toolkit'
import appStore, { initialState as appInitialState } from './app'

export interface IStore {
  app: typeof appInitialState
}

export const store = configureStore({
  reducer: {
    app: appStore,
  },
})
