import React from 'react'
import * as Styled from './index.styles'

import logo from '../../../assets/logo-white.svg'

export const Header: React.FC = () =>{
  return (
    <Styled.Container>
      <Styled.LogoImage src={logo} />
    </Styled.Container>
  )
}
