import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { App } from './app/App'
import { BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux'
import { store } from './state/store'
import { ThemeProvider } from '@emotion/react'
import { theme } from './constants/theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
