import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../state/store'
import { LoginForm } from '../routes/Login'
import { useApiContext } from '../api/apiContext'
import { Api } from '../api'
import { login } from '../state/app'

export const LoginRequired: React.FC<{} & React.PropsWithChildren> = ({ children }) => {
  const token = useSelector((state: IStore) => state?.app?.token)
  const apiContext = useApiContext()
  const dispatch = useDispatch()
  const reloadInterval = useRef<number>()

  // prolong session interval
  useEffect(() => {
    window.clearInterval(reloadInterval.current)
    reloadInterval.current = window.setInterval(async () => {
      if (token) {
        const session = apiContext.setData(await Api.getSession(token)).session
        dispatch(login({ token: session.token, expires: session.expiration }))
      }
    }, 10000)
    return () => window.clearInterval(reloadInterval.current)
  }, [token])

  if (!token) {
    return (<LoginForm />)
  }

  return <>{children}</>
}
